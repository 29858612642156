@font-face {
  font-family: "KaushanScript";
  src: local("KaushanScript"),
    url("./fonts/Kaushan_Script/KaushanScript-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Nunito-Italic";
  src: local("Nunito-Italic"),
    url("./fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
}

.opened {
  right: 0 !important;
}

.menubuttonopened > span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(5px, 1px);
}

.menubuttonopened > span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
}

.menubuttonopened > span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50% !important;
  border-radius: 0 10px 10px 0;
  transform: translate(14px, -7px) rotatez(45deg);
}

.visible {
  visibility: visible !important;
}

.delete-button {
  color: red;
  border-radius: 50%;
  position: absolute;
  right: 3px;
  top: 3px;
  height: 30px;
  width: 30px;
  z-index: 2;
}
.delete-button:hover {
  cursor: pointer;
}

.delete-button:hover > span {
  visibility: visible;
}
